<template>
  <AppHeader v-if="order" class="header">
    <BackButton :to="{ name: 'order-bags', params: { id: order.id } }" />
  </AppHeader>
  <div v-if="order">
    <div class="infobox">
      <div class="infobox__container">
        <img
          class="infobox__img"
          src="@/assets/images/print-labels@x2.png"
          alt="Print Labels"
          data-e2e="print-confirmation-img"
        />

        <h1 class="infobox__title mb-2">
          {{ $t('components.label-print-print-block.infobox-title.text') }}
        </h1>
        <p class="infobox__subtitle">
          {{ $t('components.label-print-print-block.infobox-subtitle.text') }}
          <a
            class="infobox__print"
            @click.prevent="print"
            data-e2e="print-confirmation-retry-print"
          >
            {{
              $t(
                'components.label-print-print-block.infobox-subtitle-retry-link.text',
              )
            }}
          </a>
        </p>
      </div>
      <footer class="infobox__footer">
        <Button btnOutline @click="buttonClick" data-e2e="print-successful-btn">
          {{
            $t(
              'components.label-print-print-block.infobox-button-confirm-success.text',
            )
          }}
        </Button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Button from '@/features/ui/components/Button.vue';
import { Order } from '@/features/orders';
import { BackButton, AppHeader } from '@/features/ui';

export default defineComponent({
  name: 'PrintBlock',
  components: { Button, BackButton, AppHeader },
  emits: ['buttonClick', 'print'],
  props: {
    order: {
      type: Object as PropType<Order>,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const buttonClick = () => emit('buttonClick');
    const print = () => emit('print');

    return {
      buttonClick,
      print,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  padding: 16px;
}
.label-generator {
  display: none;
}

.infobox {
  display: flex;
  height: 90vh;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__img {
    width: 125px;
    height: 136px;
  }

  &__title {
    font-size: 21px;
  }

  &__subtitle {
    padding: 0 24px;
    font-size: 16px;
  }

  &__print {
    text-decoration: underline;
    color: #0d3a93;
    cursor: pointer;
  }

  &__footer {
    padding: 32px 24px;
    height: 114px;
    border-top: 2px solid $stroke-default-color;
  }
}
</style>
