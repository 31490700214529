import { computed, ref, watch, WatchStopHandle } from 'vue';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { Order, OrderBag, UsedStorageZone } from '@/features/orders';
import { StorageZone } from '@/features/storageZone';
import { TemperatureClassLabels } from '@/features/products/types';
import { UseOrder } from '../types';

export function useOrder(): UseOrder {
  const order = ref<Order | undefined>();
  const orderId = ref('');
  const loading = ref(false);

  let watcher: WatchStopHandle | null = null;

  const setStorageZonesQuantity = (
    storageZone: UsedStorageZone,
    quantity: number,
  ) => {
    const orderStorageZones = order.value?.storageZones.find(
      (item) => item.storageZoneId === storageZone.storageZoneId,
    );

    storageZone.quantity = quantity;

    if (orderStorageZones) {
      orderStorageZones.quantity = quantity;
      return;
    }

    order.value?.storageZones.push(storageZone);
  };

  const setNumberOfLabels = (quantity: number) => {
    if (order.value) {
      order.value.numberOfLabels = quantity;
    }
  };

  const setBagQuantity = (bag: OrderBag, quantity: number) => {
    bag.quantity = quantity;
  };

  const loadOrder = async (id: string): Promise<void> => {
    loading.value = true;
    orderId.value = id;
    const result = await entityRepositoryPlugin.get().getById(Order, {
      id,
    });
    order.value = result.value;

    if (watcher) {
      watcher();
      watcher = null;
    }

    watcher = watch(result, () => {
      order.value = result.value;
    });
    loading.value = false;
  };
  const getOrderProductTemperatureClass = async (): Promise<StorageZone[]> => {
    const storageZones = (
      await entityRepositoryPlugin.get().getAll(StorageZone)
    ).value;
    if (!order.value) {
      return [];
    }
    const orderTemperatureClass: StorageZone[] = [];
    storageZones.forEach((storageZone) => {
      const temperatureClassNamesForStorageZone = TemperatureClassLabels.filter(
        (temperatureClassLabel) =>
          temperatureClassLabel.label === storageZone.title.toLowerCase(),
      );
      const foundItemWithStorageZone = order.value?.items.some((item) =>
        temperatureClassNamesForStorageZone?.find(
          (temperatureClass) =>
            temperatureClass.TemperatureClassName ===
              item.product.temperatureClass && item.quantity !== 0,
        ),
      );
      if (foundItemWithStorageZone) {
        orderTemperatureClass.push(storageZone);
      }
    });
    return orderTemperatureClass;
  };
  const setOrderStorageZones = async (): Promise<void> => {
    const storageZones = (
      await entityRepositoryPlugin.get().getAll(StorageZone)
    ).value;

    if (order.value) {
      const orderTempClass = await getOrderProductTemperatureClass();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      order.value.storageZones = storageZones.map(
        (storageZone: StorageZone) => {
          const orderBag = order.value?.storageZones.find(
            (bag) => bag.storageZoneId.id === storageZone.id,
          );
          if (orderBag) {
            return orderBag;
          }

          const orderStorageZone = orderTempClass.find(
            (tempClass) => tempClass.title === storageZone.title,
          );
          if (orderStorageZone) {
            return {
              quantity: 1,
              storageZoneId: storageZone,
            };
          }

          return {
            quantity: 0,
            storageZoneId: storageZone,
          };
        },
      );
    }
  };

  const bags = computed(() => {
    return order.value?.bags;
  });

  const storageZones = computed(() => {
    return order.value?.storageZones;
  });

  const boxLabelNeeded = computed(() => {
    return (
      order.value?.storageZones.reduce((acc: number, item) => {
        acc += item.quantity;
        return acc;
      }, 0) || 0
    );
  });

  const boxBagsNeeded = computed(() => {
    return (
      order.value?.bags.reduce((acc: number, item) => {
        acc += item.quantity;
        return acc;
      }, 0) || 0
    );
  });

  return {
    order,
    loadOrder,
    boxLabelNeeded,
    boxBagsNeeded,
    setBagQuantity,
    setStorageZonesQuantity,
    setNumberOfLabels,
    bags,
    storageZones,
    setOrderStorageZones,
    getOrderProductTemperatureClass,
    loading,
  };
}
