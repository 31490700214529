import { useRouter } from 'vue-router';
import { computed, ComputedRef, ref, Ref } from 'vue';
import { Order, UsedStorageZone } from '@/features/orders';
import { configurationServicePlugin } from '@/features/configuration';
import { PrinterFeatureOptions } from '@/features/review';
import { openLabelPrintPage } from '../helpers/printLabel';
import { ZplTemplateService } from '../services/zpl-template-service';
import { PrintedLabel } from '../types';

export function useOrderLabels(
  order: Ref<Order | null | undefined>,
  maxLabelsInQRCode: Ref<number> = ref(0),
): {
  labels: ComputedRef<PrintedLabel[]>;
  zplLabels: ComputedRef<string>;
  zplLabelQRCodes: ComputedRef<string[]>;
  redirectToPrintPage: (orderId: string) => Promise<void>;
} {
  const zplTemplateService = new ZplTemplateService();
  const labels = computed((): PrintedLabel[] => {
    if (!order?.value) {
      return [];
    }

    if (hasDeliveryUnits.value) {
      return order.value.deliveryUnits.map((unit, index) => ({
        code: unit.code,
        bagNumber: index,
      }));
    }

    const selectedBags: UsedStorageZone[] = order.value.storageZones.filter(
      (label: UsedStorageZone) => label.quantity > 0,
    );

    return selectedBags.reduce(
      (arr: PrintedLabel[], currentBag): PrintedLabel[] => {
        for (let index = 0; index < currentBag.quantity; index++) {
          arr.push({
            storageZone: {
              total: currentBag.quantity,
              name: currentBag.storageZoneId?.title,
              id: currentBag.storageZoneId?.id,
            },
            bagNumber: index + 1,
          });
        }
        return arr;
      },
      [],
    );
  });

  const hasDeliveryUnits = computed(() => {
    return Boolean(order?.value?.deliveryUnits.length);
  });

  const zplLabelQRCodes = computed((): string[] => {
    if (
      !order?.value ||
      !labels.value.length ||
      maxLabelsInQRCode.value === 0
    ) {
      return [];
    }

    const zplLabelQRCodes: string[] = [];

    // Split labels array to chunks since one QR code can't include more then N labels.
    for (
      let index = 0;
      index < labels.value.length;
      index += maxLabelsInQRCode.value
    ) {
      const chunk = labels.value.slice(index, index + maxLabelsInQRCode.value);
      zplLabelQRCodes.push(
        zplTemplateService.getZebraPrinterLabels(
          zplOrderData.value,
          chunk,
          isCustomerLastNameVisible.value,
        ),
      );
    }

    return zplLabelQRCodes;
  });

  // #region Customer Last Name on label
  const configurationService = configurationServicePlugin.get();
  const isCustomerLastNameVisible = ref(false);

  const execute = async () => {
    isCustomerLastNameVisible.value =
      await configurationService.getFeatureOption(
        'printingStorageLabels',
        'customerLastNameOnLabel',
        'boolean',
      );
  };

  void execute();
  // #endregion

  const zplLabels = computed((): string => {
    if (!order?.value || !labels.value.length) {
      return '';
    }

    return zplTemplateService.getZebraPrinterLabels(
      zplOrderData.value,
      labels.value,
      isCustomerLastNameVisible.value,
    );
  });

  const zplOrderData = computed(() => {
    const { pickupCode, customer, orderReference, startTime } =
      order.value as Order;
    return {
      pickupCode,
      customer,
      orderReference,
      startTime,
    };
  });

  const router = useRouter();

  const redirectToPrintPage = async (orderId: string) => {
    const isPrintingFeatureActive = (
      await configurationServicePlugin
        .get()
        .isFeatureActive('printingStorageLabels')
    ).value;

    let targetRoute = 'complete-picking-order';

    if (isPrintingFeatureActive) {
      const printerFeatureOption = await configurationServicePlugin
        .get()
        .getFeatureOption('printingStorageLabels', 'printerType', 'string');

      switch (printerFeatureOption) {
        case PrinterFeatureOptions.ZebraBrowserPrinter:
          targetRoute = 'label-print-zebra';
          break;
        case PrinterFeatureOptions.ZebraQrCodePrinter:
          targetRoute = 'label-print-zebra-qr';
          break;
        default:
          openLabelPrintPage(orderId);
      }
    }

    await router.push({
      name: targetRoute,
      params: {
        id: orderId,
      },
    });
  };

  return {
    labels,
    zplLabels,
    zplLabelQRCodes,
    redirectToPrintPage,
  };
}
