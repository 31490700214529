import { urlBuilderServicePlugin } from '@/features/url';
import { PrintedLabel } from '../types';

export const openLabelPrintPage = (id: string): void => {
  window.open(
    `${urlBuilderServicePlugin.get().getHostUrl()}/print/${id}`,
    '_blank',
  );
};

export const getQrData = (pickupCode: string, label: PrintedLabel): string => {
  if (label.storageZone) {
    return `pickupCode=${pickupCode};storageZoneId=${label.storageZone.id};labelNumber=${label.bagNumber};`;
  } else if (label.code) {
    return label.code;
  }
  return '';
};
